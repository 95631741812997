import React from 'react';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Header from '@sections/BlogDetail/Header';
import Grid, { Row, Column } from '@components/core/Grid';
import FeaturedText from '@sections/BlogDetail/FeaturedText';
import AboutTheAuthor from '@sections/BlogDetail/AboutTheAuthor';
import Content from '@sections/BlogDetail/Content';
import RelatedArticles from '@sections/BlogDetail/RelatedArticles';
import PostMetadata from '@sections/BlogDetail/PostMetadata';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const BlogDetail = ({
    data: {
        articleInfo: {
            id,
            frontmatter: {
                title,
                category,
                published,
                image,
                seo,
                featuredText,
            },
            htmlAst,
        },
        author: {
            frontmatter: { name, image: authorImage, bio, aboutTheAuthor },
        },
    },
    location,
}) => {
    const { md } = useBreakpoint();

    const seoData = {
        title: (seo && seo.title) || title,
        description: (seo && seo.description) || '',
        image: (seo && seo.image && seo.image.publicURL) || '',
    };

    return (
        <Layout>
            <SEO
                title={seoData.title}
                description={seoData.description}
                image={seoData.image}
            />
            <Header title={title} image={image} />
            <Grid>
                <Row>
                    {!md && (
                        <Column>
                            <PostMetadata
                                title={title}
                                author={name}
                                authorImage={authorImage}
                                authorBio={bio}
                                category={category}
                                publishedDate={published}
                                location={location}
                            />
                        </Column>
                    )}
                    <Column md="8">
                        {featuredText && (
                            <FeaturedText>{featuredText}</FeaturedText>
                        )}
                    </Column>
                    <Column md="8">
                        <Content htmlAst={htmlAst} />
                        {aboutTheAuthor && (
                            <AboutTheAuthor about={aboutTheAuthor} />
                        )}
                    </Column>
                    {md && (
                        <Column md="3" offsetMd="1">
                            <PostMetadata
                                title={title}
                                author={name}
                                authorImage={authorImage}
                                authorBio={bio}
                                category={category}
                                publishedDate={published}
                                location={location}
                            />
                        </Column>
                    )}
                </Row>
            </Grid>
            <RelatedArticles shownArticle={id} category={category} />
        </Layout>
    );
};

BlogDetail.propTypes = {
    data: PropTypes.shape({
        articleInfo: PropTypes.objectOf(PropTypes.any),
        author: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

export default BlogDetail;

export const pageQuery = graphql`
    query blogDetail($id: String!, $author: String!) {
        articleInfo: markdownRemark(id: { eq: $id }) {
            id
            frontmatter {
                title
                seo {
                    title
                    description
                    image {
                        publicURL
                    }
                }
                published(formatString: "DD MMMM YYYY")
                featuredText
                category
                image {
                    childImageSharp {
                        fluid(maxWidth: 1200) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            htmlAst
        }
        author: markdownRemark(
            frontmatter: { name: { eq: $author } }
            fields: { slug: { glob: "/authors/*" } }
        ) {
            frontmatter {
                name
                aboutTheAuthor
                image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                bio
            }
        }
    }
`;
