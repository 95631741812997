import React from 'react';
import Text from '@components/core/Text';
import Section from '@components/core/Section';
import PropTypes from 'prop-types';
import styles from './about-the-author.module.scss';

const AboutTheAuthor = ({ about }) => {
    return (
        <Section className={styles.wrapper}>
            <Text className={styles.title} size="h5" as="h5">
                About the author
            </Text>
            <Text size="paragraphMd" as="p">
                {about}
            </Text>
        </Section>
    );
};

AboutTheAuthor.propTypes = {
    about: PropTypes.string.isRequired,
};

export default AboutTheAuthor;
