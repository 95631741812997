import React from 'react';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import Image from 'gatsby-image';
import FolderIcon from '@components/Icons/folder.svg';
import CalendarIcon from '@components/Icons/calendar.svg';
import ShareIcons from '@components/ShareIcons';
import styles from './post-metadata.module.scss';

const PostMetadata = ({
    title,
    author,
    authorImage,
    authorBio,
    category,
    publishedDate,
    location,
}) => {
    return (
        <aside className={styles.wrapper}>
            <div>
                <Image
                    className={styles.authorImage}
                    fluid={authorImage.childImageSharp.fluid}
                />
                <Text className={styles.author} size="paragraphMd">
                    {author}
                </Text>
                <Text
                    className={styles.authorBio}
                    size="paragraphSm"
                    color="gray"
                >
                    {authorBio}
                </Text>
                <div className={styles.postInfo}>
                    <Text
                        className={styles.postInfoText}
                        size="paragraphSm"
                        color="gray"
                    >
                        <FolderIcon className={styles.postInfoIcon} />{' '}
                        <span>{category}</span>
                    </Text>
                    <Text
                        className={styles.postInfoText}
                        size="paragraphSm"
                        color="gray"
                    >
                        <CalendarIcon className={styles.postInfoIcon} />{' '}
                        <span>{publishedDate}</span>
                    </Text>
                </div>
                <div>
                    <Text
                        className={styles.shareTitle}
                        size="paragraphMd"
                        as="p"
                    >
                        Share
                    </Text>
                    <ShareIcons locationPath={location} title={title} />
                </div>
            </div>
        </aside>
    );
};

PostMetadata.propTypes = {
    title: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    authorImage: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            title: PropTypes.string,
            alt: PropTypes.string,
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
    authorBio: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    publishedDate: PropTypes.string.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};
export default PostMetadata;
