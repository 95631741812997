import React from 'react';
import ArticleCard from '@components/Cards/Article';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PropTypes from 'prop-types';
import styles from './articles-grid.module.scss';

const ArticlesGrid = ({ articles, mobileItems, desktopItems }) => {
    const { md } = useBreakpoint();
    const size = md ? desktopItems : mobileItems;
    const items = articles.slice(0, size);
    return (
        <div>
            <div className={styles.articlesContainer}>
                {items.map(article => {
                    return (
                        <ArticleCard
                            classCardWrapper={styles.articleItem}
                            classImageWrapper={styles.imageArticle}
                            classInfo={styles.info}
                            key={article.published}
                            article={article}
                            sizeTitle="paragraphMd"
                            layoutDistribution={md ? 'vertical' : 'horizontal'}
                            showFixedImg={!md}
                        />
                    );
                })}
            </div>
        </div>
    );
};

ArticlesGrid.propTypes = {
    articles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    mobileItems: PropTypes.number,
    desktopItems: PropTypes.number,
};

ArticlesGrid.defaultProps = {
    mobileItems: 4,
    desktopItems: 6,
};

export default ArticlesGrid;
