import React from 'react';
import PropTypes from 'prop-types';
import rehypeReact from 'rehype-react';
import PostQuote from '@components/PostQuote';
import PostImage from '@components/PostImage';
import PostIframe from '@components/PostIframe';
import PostLink from '@components/PostLink';
import Text from '@components/core/Text';
import styles from './content.module.scss';

// eslint-disable-next-line new-cap
const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
        'z-quote': PostQuote,
        'z-image': PostImage,
        'z-iframe': PostIframe,
        a: PostLink,
        ...['h1', 'h2', 'h3', 'h4', 'h5'].reduce((acc, tag) => {
            // eslint-disable-next-line react/prop-types
            acc[tag] = ({ children }) => {
                return (
                    <Text as={tag} size={tag}>
                        {children}
                    </Text>
                );
            };

            return acc;
        }, {}),
    },
    Fragment: React.Fragment,
}).Compiler;

const Content = ({ htmlAst }) => {
    return (
        <Text className={styles.wrapper} as="article" size="paragraphMd">
            {renderAst(htmlAst)}
        </Text>
    );
};

Content.propTypes = {
    htmlAst: PropTypes.objectOf(PropTypes.any),
};

Content.defaultProps = {
    htmlAst: '',
};

export default Content;
