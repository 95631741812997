import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '@components/core/Text';
import QuoteIcon from '@components/Icons/quote.svg';
import styles from './quote.module.scss';

const textSizes = {
    medium: 'paragraphMd',
    big: 'h5',
    large: 'h4',
};

const Quote = ({
    children,
    className,
    size,
    shouldRenderEndQuote,
    variant,
}) => {
    const classNames = cx(
        styles.wrapper,
        {
            [styles[variant]]: true,
            [styles[size]]: true,
        },
        className
    );

    return (
        <div className={classNames}>
            <QuoteIcon className={styles.icon} />
            <Text
                as="blockquote"
                size={textSizes[size]}
                className={styles.quote}
            >
                {children}
                {shouldRenderEndQuote && '”'}
            </Text>
        </div>
    );
};

Quote.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    size: PropTypes.oneOf(['medium', 'big', 'large']),
    shouldRenderEndQuote: PropTypes.bool,
    variant: PropTypes.oneOf(['inline', 'inlineBlock']),
};

Quote.defaultProps = {
    children: '',
    className: '',
    size: 'medium',
    shouldRenderEndQuote: true,
    variant: 'inline',
};

export default Quote;
