import { useStaticQuery, graphql } from 'gatsby';
import { replaceSlug } from '@utils/utils';

export const formatData = nodesData => {
    if (!nodesData || !Array.isArray(nodesData)) {
        return nodesData;
    }

    return nodesData.reduce((acc, el) => {
        const newSlug = replaceSlug(el.fields.slug, el.frontmatter.fieldSlug);

        acc.push({
            id: el.id,
            slug: newSlug || el.fields.slug,
            image: el.frontmatter.image,
            topic: el.frontmatter.category,
            title: el.frontmatter.title,
            published: el.frontmatter.published,
        });
        return acc;
    }, []);
};

export default () => {
    const data = useStaticQuery(graphql`
        query GetAllArticles {
            allArticles: allMarkdownRemark(
                sort: { fields: [frontmatter___published], order: DESC }
                filter: {
                    fields: { slug: { glob: "/insights/blog/*" } }
                    frontmatter: { archive: { eq: false } }
                }
            ) {
                articles: nodes {
                    id
                    frontmatter {
                        title
                        published
                        featuredText
                        category
                        featured
                        fieldSlug: slug
                        image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                    presentationWidth
                                }
                                fixed(width: 114, height: 118) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    `);
    const { articles } = data.allArticles;
    return articles && formatData(articles);
};
