import React from 'react';
import Text from '@components/core/Text';
import PopTypes from 'prop-types';
import styles from './featured-text.module.scss';

const FeaturedText = ({ children }) => {
    return (
        <Text className={styles.wrapper} size="paragraphMd" as="p">
            {children}
        </Text>
    );
};

FeaturedText.propTypes = {
    children: PopTypes.node,
};

FeaturedText.defaultProps = {
    children: null,
};

export default FeaturedText;
