/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import styles from './post-iframe.module.scss';

const PostIframe = ({ caption, children }) => {
    if (!children) {
        return null;
    }

    return (
        <figure className={styles.wrapper}>
            <div className={styles.ratioWrapper}>{children}</div>
            {caption && (
                <Text as="figcaption" color="gray" size="paragraphXs">
                    {caption}
                </Text>
            )}
        </figure>
    );
};

PostIframe.propTypes = {
    caption: PropTypes.string,
    children: PropTypes.node,
};

PostIframe.defaultProps = {
    caption: '',
    children: '',
};

export default PostIframe;
