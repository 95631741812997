import React from 'react';
import PropTypes from 'prop-types';

const PostLink = ({ href, children }) => {
    return !href.includes('zemoga') ? (
        <a href={href} target="__blank" rel="noopener noreferrer">
            {children}
        </a>
    ) : (
        <a href={href}>{children}</a>
    );
};

PostLink.propTypes = {
    href: PropTypes.string,
    children: PropTypes.string,
};

PostLink.defaultProps = {
    href: 'www.zemoga.com',
    children: 'Test Link',
};

export default PostLink;
