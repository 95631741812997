import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PropTypes from 'prop-types';
import Grid, { Row, Column } from '@components/core/Grid';
import Text from '@components/core/Text';
import AnchorToSection from '@components/core/AnchorToSection';
import ArticlesGrid from '@components/ArticlesGrid';
import Section from '@components/core/Section';
import useAllArticles from '@hooks/Insights/useAllArticles';
import styles from './related-articles.module.scss';

const RelatedArticles = ({ category, shownArticle }) => {
    const { md } = useBreakpoint();
    const articles = useAllArticles();
    const relatedArticles = articles.reduce((acc, article) => {
        if (article.topic === category && article.id !== shownArticle) {
            acc.push({ ...article });
        }
        return acc;
    }, []);
    const allArticles = 'All articles';

    return (
        <Section>
            <Grid className={styles.container}>
                <Row>
                    <Column>
                        <div className={styles.anchorSectionContainer}>
                            <Text as="h2" size="h2">
                                Related Articles
                            </Text>
                            {md && (
                                <AnchorToSection
                                    sizeIcon="4"
                                    toPath="/insights"
                                    className={
                                        styles.allArticlesAnchorToSection
                                    }
                                    animatedInHover
                                >
                                    <Text as="span" size="paragraphMd">
                                        {allArticles}
                                    </Text>
                                </AnchorToSection>
                            )}
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <ArticlesGrid
                            articles={relatedArticles}
                            desktopItems={3}
                        />

                        <AnchorToSection
                            sizeIcon="4"
                            toPath="/insights"
                            className={styles.mobileLink}
                        >
                            <Text as="span" size="paragraphMd">
                                {allArticles}
                            </Text>
                        </AnchorToSection>
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

RelatedArticles.propTypes = {
    category: PropTypes.string.isRequired,
    shownArticle: PropTypes.string.isRequired,
};

export default RelatedArticles;
