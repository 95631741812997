import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import Text from '@components/core/Text';
import styles from './post-image.module.scss';

const PostImage = ({ alt, caption, fluid, title }) => {
    return (
        <figure className={styles.wrapper}>
            <div className={styles.imageWrapper}>
                <Image alt={alt} title={title} fluid={JSON.parse(fluid)} />
            </div>
            {caption && (
                <Text as="figcaption" color="gray" size="paragraphXs">
                    {caption}
                </Text>
            )}
        </figure>
    );
};

PostImage.propTypes = {
    alt: PropTypes.string,
    caption: PropTypes.string,
    fluid: PropTypes.string,
    title: PropTypes.string,
};

PostImage.defaultProps = {
    alt: '',
    caption: '',
    fluid: '',
    title: '',
};

export default PostImage;
