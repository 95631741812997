import React from 'react';
import Section from '@components/core/Section';
import Grid from '@components/core/Grid';
import Eyebrow from '@components/core/Eyebrow';
import Text from '@components/core/Text';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import styles from './header.module.scss';

const Header = ({ title, image }) => {
    return (
        <Section className={styles.header}>
            <Grid>
                <Eyebrow className={styles.eyebrow} casing="uppercase">
                    Insights
                </Eyebrow>
                <Text className={styles.title} as="h2" size="h1">
                    {title}
                </Text>
                {image && (
                    <div className={styles.imageWrapper}>
                        <div>
                            <Image fluid={image.childImageSharp.fluid} />
                        </div>
                    </div>
                )}
            </Grid>
        </Section>
    );
};

Header.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            title: PropTypes.string,
            alt: PropTypes.string,
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
};

export default Header;
