import React from 'react';
import Quote from '@components/core/Quote';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import styles from './post-quote.module.scss';

const PostQuote = ({ author, quote }) => {
    return (
        <figure className={styles.wrapper}>
            <Quote className={styles.quote} size="big">
                {quote}
            </Quote>
            {author && (
                <Text
                    className={styles.author}
                    as="figcaption"
                    size="paragraphMd"
                    color="white"
                >
                    - {author}
                </Text>
            )}
        </figure>
    );
};

PostQuote.propTypes = {
    author: PropTypes.string,
    quote: PropTypes.string.isRequired,
};

PostQuote.defaultProps = {
    author: null,
};

export default PostQuote;
